import React from "react"

import { useTranslations } from "../../utils/intl"
import { ContentWrapper, shevy, theme, toEm } from "../index"

const Contact: React.FC = props => {
  const { it } = useTranslations()
  return (
    <ContentWrapper {...props}>
      <h2
        css={{
          maxWidth: toEm(673, 40),
          ...shevy.h3,
        }}
      >
        {it([
          "If you have any questions, please contact us",
          "För frågor och samarbeten kontakta oss gärna",
        ])}
      </h2>
      <p>
        <a
          css={{
            fontSize: toEm(30),
            color: theme.colors.blue,
            textDecoration: "none",
          }}
          href="mailto:anders@runbit.se"
        >
          anders@runbit.se
        </a>
      </p>

      <hr css={{ borderWidth: 1, marginTop: toEm(46) }}></hr>
    </ContentWrapper>
  )
}

export default Contact
